.flexbox {
    display: flex;
    flex-direction: row;
    justify-content: center;
    
  }
  
  .flexbox > div {
   
    
    
    text-align: center;
    line-height: 75px;
    font-size: 30px;
  }

  

  @media only screen and (min-width: 320px) and (max-width: 768px){

    .flexbox {
        display: flex;
        flex-direction: column !important;
        justify-content: center;
        
        
      }

    

  }