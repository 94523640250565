input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}


/* Create a custom radio button */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: #eee;
  border-radius: 50%;
}

/* On mouse-over, add a grey background color */
.container:hover input~.checkmark {
  background-color: #ccc;
}

/* When the radio button is checked, add a blue background */
input:checked~.checkmark {
  background-color: #2196F3;
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the indicator (dot/circle) when checked */
input:checked~.checkmark:after {
  display: block;
}

/* Style the indicator (dot/circle) */
.checkmark:after {
  top: 9px;
  left: 9px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: white;
}


.wizard-form-input select {
  width: 100% !important;
  height: 95px !important;
  padding: 0px 40px !important;
  border: 3px solid #b4d4e4 !important;
}

.duration-box {
  display: block;

  height: 185px;

  padding-top: 35px;
  position: relative;
  border: 2px solid #b4d4e4 !important;
}

.duration-box .title {
  color: #474747;
  display: block;
  font-size: 60px;
  font-weight: 600;
  line-height: 2;
  margin-bottom: 5px;
}

.duration-box span {
  line-height: 1;
  color: #474747;
  font-size: 26px;
  font-weight: 600;
  text-transform: uppercase;
}

.duration-option {
  position: relative;
  margin-bottom: 0;
  width: 100%;
}

.duration-option .d-checkbox {
  display: none;
}

.duration-option .checkbox-circle-tick {
  position: absolute;
  top: 15px;
  right: 45px;
}

.duration-option .checkbox-circle-tick:before {
  content: "";
  width: 30px;
  height: 30px;
  border-radius: 100%;
  position: absolute;
  border: 2px solid #b4d4e4;
}

.duration-option .checkbox-circle-tick:after {
  top: 5px;
  left: 5px;
  content: "";
  width: 20px;
  height: 20px;
  display: none;
  border-radius: 100%;
  position: absolute;
  background-color: #ffc107;
}

.duration-option .d-checkbox:checked~.duration-box {
  border: 2px solid #5756a2;
  -webkit-box-shadow: 0px 5px 35px 0px rgba(0, 0, 0, 0.27);
  box-shadow: 0px 5px 35px 0px rgba(0, 0, 0, 0.27);
}

.duration-option .d-checkbox:checked~.checkbox-circle-tick:after {
  display: block;
}

.duration-option .d-checkbox:checked~.checkbox-circle-tick:before {
  border-color: #ffc107;
}


.form-check1 {
  position: relative;
  top: -13px;
  display: inline-block;

  margin: 7px 0px 0px 19px;
}

#gridCheck {
  height: 88px;
  width: 27px;
  position: absolute;
  top: -36px;
  left: 14px;
}

.form-check-label {
  margin-bottom: 0;
  padding: 0px 0px 0px 30px;
}

/* ==========================================================================
wizard 4
========================================================================== */


.service-checkbox {
  display: none;
}

.w-service-box {
  width: 250px;
  height: 250px;
  float: left;
  margin: 15px;
  padding: 10px 10px;
  /*background-color: #fff;
  */
  border: 3px solid #ffc107;
  border-radius: 4px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  -o-border-radius: 4px;

  cursor: pointer;
}

.tooltip-info:before {
  position: absolute;
  content: "";
  height: 30px;
  width: 30px;
  top: 15px;
  right: 15px;
  display: none;
  border-radius: 100%;
  background-color: #ffc107;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  -ms-border-radius: 100%;
  -o-border-radius: 100%;
}

.tooltip-info:after {
  position: absolute;
  content: "\f00c";
  /* content: ""; */
  top: 18px;
  color: #fff;
  right: 22px;
  font-weight: 900;
  display: none;
  display: none;
  font-family: "Font Awesome 5 Free";
}

.service-text {
  color: #5756a2;
  font-size: 24px;
  font-weight: 500;
}

.w-service-box .option-seclect {
  bottom: -35px;
  left: -4px;
  width: 103%;
  padding: 5px;
  font-size: 16px;
  display: none;
  font-weight: 500;
  position: absolute;
  background-color: #ffc107;
  cursor: pointer !important;

}

.w-service-box .option-seclect span {
  color: #fff;
}

.service-checkbox:checked~.w-service-box .tooltip-info:before,
.service-checkbox:checked~.w-service-box .tooltip-info:after {
  display: block;
}

.service-checkbox:checked~.w-service-box {
  border: 3px solid #ffc107;
}

.service-checkbox:checked~.w-service-box .option-seclect {
  display: block;
}

.slick-dots li.slick-active {
  width: 35px;
  height: 10px;
  background: #2c39a4;
  border-radius: 5px;
}

.slick-dots li {
  width: 15px;
  height: 10px;
  background: #ccc;
  display: inline-block;
  margin: 0 8px;
  border-radius: 5px;
  cursor: pointer;
}

.slick-dots {
  position: absolute;
  left: 50%;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
}

.slick-dots button {
  font-size: 0;
  visibility: hidden;
}


.map_height {
  height: 170px;
}