//  theme color 
$color_1: #2f3753;
$color_2: #dc4549;
$color_3: #fff;
$color_4: #ffffff;
$color_5: #333333;
$color_6: #fff;
$color_7: #fff;
$color_8: #0B6EB0;
$color_9: #f5a93f;
$color_10: #25283a;
$color_11: #ffc107;
$color_12: #3f6b92;
$color_13: #010101;
$color_14: #4cc7d6;
$color_15: #eef7f8;
$color_16: #faf9f2;
$color_17: #6c6d70;
$color_18: #ff0000;
$color_19: #c4c5c7;
$color_20: var(--white);
$color_21: #848484;
$color_22: #777777;
$color_23: rgba(255, 255, 255, 0.07);
$color_24: #ff7e00;
$color_25: #9a4cd6;
$color_26: #222222;
$color_27: #121212;
$color_28: rgba(255, 255, 255, 0.50);
$color_29: #f4c125;
$color_30: #5ab056;
$color_31: #f4c126;
$color_32: red;
$color_33: #000;
$color_34: #3b86bd;
$font-family_1: 'PT Sans', sans-serif;
$font-family_2: 'Playfair Display', cursive;
$font-family_3: 'Font Awesome 5 Free';
$font-family_4: "Flaticon";
$font-family_5: 'Playfair Display', serif;
$font-family_6: 'Signature', serif;
$background-color_1: #ffc107;
$background-color_2: rgba(0, 0, 0, 0);
$background-color_3: #f4c125;
$background-color_4: #222222;
$background-color_5: #ffffff;
$background-color_6: transparent;
$background-color_7: #1d1d1d;
$background-color_8: #1e2436;
$background-color_9: #1d1d1dc2;
$background-color_10: #eef7f8;
$background-color_11: #333333;
$background-color_12: #9a4cd6;
$background-color_13: rgba(220, 69, 73, 0.90);
$background-color_14: #25283a;
$background-color_15: #faf9f2;
$background-color_16: #3f6b92;
$background-color_17: rgba(0, 0, 0, 0.64);
$background-color_18: #dc4549;
$background-color_19: #fff;
$background-color_20: #0d6fb0;
$background-color_21: #28a745;
$background-color_22: #ccc;
$background-color_23: #007bff;
$background-color_24: white;
$background-color_25: #f44336;
$border-color_1: #f5a93f;
$border-color_2: #4cc7d6;
$border-color_3: #ff7a21;
$border-color_4: #ff7e00;
$border-color_5: #3f6b92;
$border-color_6: #0B6EB0;
$border-top-color_1: var(--yellow);
$border-bottom-color_1: #4cc7d6;
$border-bottom-color_2: #0B6EB0;
$border-bottom-color_3: #3f6b92;
$border-bottom-color_4: #f5a93f;
$border-bottom-color_5: rgba(0, 0, 0, 0.10);

